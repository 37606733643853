// extracted by mini-css-extract-plugin
export var cafeAqours = "cafe-aqours-module--cafeAqours--KhdkY";
export var linkButton = "cafe-aqours-module--link-button--45ym5";
export var vol1 = "cafe-aqours-module--vol1--1kDzY";
export var vol2 = "cafe-aqours-module--vol2--2_Zyn";
export var youtube = "cafe-aqours-module--youtube--1TQ3c";
export var mellon = "cafe-aqours-module--mellon--16KaX";
export var booth = "cafe-aqours-module--booth--2HYKn";
export var translateButton = "cafe-aqours-module--translateButton--3z5Yq";
export var translateButton__hide = "cafe-aqours-module--translateButton__hide--2D2IA";
export var heroLogo = "cafe-aqours-module--heroLogo--10TLj";
export var heroLogo__seriesTitle = "cafe-aqours-module--heroLogo__seriesTitle--3LoDx";
export var heroLogo__seriesDescription = "cafe-aqours-module--heroLogo__seriesDescription--3VB6W";
export var scrollPrompt = "cafe-aqours-module--scrollPrompt--2c3U-";
export var scrollPrompt__text = "cafe-aqours-module--scrollPrompt__text--3ODtV";
export var scrollPrompt__arrow = "cafe-aqours-module--scrollPrompt__arrow--25DSo";
export var arrowAnimation = "cafe-aqours-module--arrow-animation--25sGb";
export var stickyContainer = "cafe-aqours-module--stickyContainer--1sM-W";
export var stickyContainer__contents = "cafe-aqours-module--stickyContainer__contents--203ds";
export var stickyContainer__background__footer = "cafe-aqours-module--stickyContainer__background__footer--3-WFJ";
export var stickyContainer__background__noizy = "cafe-aqours-module--stickyContainer__background__noizy--22baA";
export var stickyContainer__background = "cafe-aqours-module--stickyContainer__background--2sqKQ";
export var about = "cafe-aqours-module--about--sYFzL";
export var about__title = "cafe-aqours-module--about__title--1W5L1";
export var about__description = "cafe-aqours-module--about__description--30Szu";
export var about__link = "cafe-aqours-module--about__link--11voX";
export var discInfo = "cafe-aqours-module--discInfo--8lea5";
export var discDetail = "cafe-aqours-module--discDetail--2TCKQ";
export var discTitle = "cafe-aqours-module--discTitle--1Yy76";
export var trackList = "cafe-aqours-module--trackList--3tjvn";
export var artwork = "cafe-aqours-module--artwork--1dvwC";
export var footerInfo = "cafe-aqours-module--footerInfo--27y-o";
export var footerInfo__help = "cafe-aqours-module--footerInfo__help--3r-LC";
export var footerInfo__helpLink = "cafe-aqours-module--footerInfo__help-link--3IhVc";
export var ownerInfo = "cafe-aqours-module--ownerInfo--19AZf";
export var faq = "cafe-aqours-module--faq--kWXbb";